import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSignup } from '../hooks/useSignup';
import InputField from '../components/authentication/InputField';
// icons
import { MdOutlineEmail, MdOutlineLock } from "react-icons/md";
import { FaRegBuilding } from "react-icons/fa";
import PasswordStrengthBar from '../components/authentication/PasswordStrengthBar';
import GoToButton from '../components/shared/GoToButton';


const ResearcherSignup = () => {
  // states for the input fields
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [institution, setInstitution] = useState('');

  // error states for the input fields (0 = untouched, 1 = valid, 2 = invalid)
  const [emailError, setEmailError] = useState(0);
  const [passwordError, setPasswordError] = useState(0);
  const [confirmPasswordError, setConfirmPasswordError] = useState(0);
  const [firstNameError, setFirstNameError] = useState(0);
  const [lastNameError, setLastNameError] = useState(0);
  const [institutionError, setInstitutionError] = useState(0);
  const [tosError, setTosError] = useState(0);

  // completion states for input fields with requirements
  const [passwordComplete, setPasswordComplete] = useState(false);

  const { signup, error, isLoading, success } = useSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false; // Local variable to track if there's any error

    // Check and set error states as needed
    if (emailError !== 1) { setEmailError(2); hasError = true; }
    if (passwordError !== 1) { setPasswordError(2); hasError = true; }
    if (confirmPasswordError !== 1) { setConfirmPasswordError(2); hasError = true; }
    if (firstNameError !== 1) { setFirstNameError(2); hasError = true; }
    if (lastNameError !== 1) { setLastNameError(2); hasError = true; }
    if (institutionError !== 1) { setInstitutionError(2); hasError = true; }
    if (tosError !== 1) { setTosError(2); hasError = true; }

    // Now, use the local variable to decide whether to proceed
    if (hasError) {
      return;
    }

    // If no errors, proceed with signup
    await signup(email, password, firstName, lastName, institution);
  }

  // Input field handlers. Properly checks for errors and sets the error state accordingly
  const firstNameHandler = (value) => {
    setFirstName(value);
    if (value.length > 0) {
      setFirstNameError(1);
    } else {
      setFirstNameError(2);
    }
  }
  const lastNameHandler = (value) => {
    setLastName(value);
    if (value.length > 0) {
      setLastNameError(1);
    } else {
      setLastNameError(2);
    }
  }
  const institutionHandler = (value) => {
    setInstitution(value);
    if (value.length > 0) {
      setInstitutionError(1);
    } else {
      setInstitutionError(2);
    }
  }
  const confirmPasswordHandler = (value) => {
    setConfirmPassword(value);
    if (password !== value) {
      setConfirmPasswordError(2);
    } else {
      setConfirmPasswordError(1);
    }
  }
  const passwordHandler = (value) => {
    setPassword(value);
    if (confirmPasswordError === 0)
      return;

    if (confirmPassword !== value) {
      setConfirmPasswordError(2);
    } else {
      setConfirmPasswordError(1);
    }
  }
  const passwordBlurHandler = (value) => {
    passwordComplete ? setPasswordError(1) : setPasswordError(2);
  }

  /**
   * Confirm that the email entered is valid
   */
  const confirmValidEmail = (value) => {
    // custom regex for email validation
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setEmailError(re.test(value) ? 1 : 2);
  }

  return (
    <div className='flex text-text-primary'>

      <div className={`relative w-full px-10 md:px-20 py-10 md:w-3/5 flex flex-col items-center justify-center h-screen transition-all duration-500 ${success ? 'pb-0 gap-10' : 'gap-0 pt-1'}`}>
        <div className="flex flex-col pb-5 gap-3">
          <Link to="/" className={`justify-center flex w-full`}>
            <img src={`${process.env.PUBLIC_URL}/logo/normative-dark.svg`} alt="logo" className='w-2/3 md:w-2/5' />
          </Link>
          {!success && <div className='py-2 w-full'>
            <h1 className="text-3xl font-bold">Sign Up</h1>
            <p className='text-text-secondary-reg text-sm'>New users will be able to log in after an admin approval. Thanks for joining Normative!</p>
          </div>}
        </div>

        {!success ? 
        <div className='w-full'>
          <form className="flex flex-col space-y-2 w-full" onSubmit={handleSubmit}>
            <div className='flex flex-col'>
              <div className='flex w-full'>
                <div className='w-1/2 pr-2'>
                  <InputField name={"First Name"} type={"text"} placeholder={"First Name"} setValue={firstNameHandler} autocomplete={'given-name'} error={firstNameError} />
                </div>
                <div className='w-1/2 pl-2'>
                  <InputField name={"Last Name"} type={"text"} placeholder={"Last Name"} setValue={lastNameHandler} autocomplete={'family-name'} error={lastNameError} />
                </div>
              </div>
            </div>
            <InputField name={"Email"} icon={<MdOutlineEmail />} type={"email"} placeholder={"Email"} setValue={setEmail} autocomplete={"email"} blurHandler={confirmValidEmail} error={emailError} />
            <InputField name={"Password"} icon={<MdOutlineLock />} type={"password"} placeholder={"Password"} setValue={passwordHandler} autocomplete={"new-password"} value={password} blurHandler={passwordBlurHandler} subComponent={<PasswordStrengthBar password={password} setComplete={setPasswordComplete} />} error={passwordError} />
            <InputField name={"Confirm Password"} icon={<MdOutlineLock />} type={"password"} placeholder={"Confirm Password"} setValue={confirmPasswordHandler} autocomplete={"new-password"} value={confirmPassword} subComponent={(confirmPasswordError === 2 ? <span className='text-red-600 text-xs'>Passwords do not match</span> : confirmPasswordError === 1 ? <span className='text-green-600 text-xs'>Passwords match</span> : <></>)} error={confirmPasswordError} />
            <InputField name={"Institution"} icon={<FaRegBuilding />} type={"text"} placeholder={"Institution"} setValue={institutionHandler} autocomplete={null} error={institutionError} />
            <div className='flex gap-2 items-center'>
              <input type="checkbox" className={`${tosError === 2 && ' ring-1 ring-red-500 rounded-xl animate-shake'}`} id="terms" name="terms" value="terms" onChange={(e) => setTosError(e.target.checked ? 1 : 2)} />
              <label htmlFor="terms" className="text-text-secondary-dark text-sm md:text-base">I agree to the <Link to="/terms" className="text-normative-blue">Terms of Service</Link></label>
              {/*  and <Link to="/privacy" className="text-normative-blue">Privacy Policy</Link> <span className='text-red-500'>*</span> */}
            </div>
            <button disabled={isLoading} className="bg-normative-blue text-white py-2 px-5 rounded-lg font-medium active:scale-95 focus:outline-none focus:ring-2 focus:ring-indigo-400">Request Account</button>
            {error && <p className='text-red-500'>{error}</p>}
            {/* <Link to="/terms" className="text-sm text-text-secondary-dark">Forgot your password?</Link> */}
          </form>
          <hr className="w-2/3" />
          <div className="flex flex-col items-center">
            <span className='text-text-secondary-reg'>Already have an account? Click here to log in instead.</span>
            <Link to="/researcher-login" className="bg-white text-center border-gray-300 border text-text-secondary-dark w-2/3 py-2 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-indigo-400">Log in</Link>
          </div>
        </div>
          : 
          <div className='flex flex-col items-center gap-5'>
            <h2 className='text-2xl font-bold'>Sign Up Successful</h2>
            <p className='text-text-secondary-reg'>Your account is pending approval by an admin. You will be notified through E-mail when your account is approved. Thanks for joining Normative!</p>
            {/* Back to home button */}
            <GoToButton to='/' text='Back to Home' reversed={true} />
          </div>
        }

      </div>


      {/* Hidden on mobile */}
      <div className="hidden w-2/5 h-screen md:flex items-center">
        <img src={`${process.env.PUBLIC_URL}/images/doctors.svg`} alt="researcher login" className="" />
      </div>
    </div>
  )
}

export default ResearcherSignup;
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import GoToButton from './GoToButton';
import AccountBubble from './AccountBubble';
import { useUser } from '../../contexts/UserContext';
import { useCookies } from 'react-cookie';
import { MdClose } from 'react-icons/md';

const Navbar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [cookies, removeCookie] = useCookies(['jwt']);
    const { state, dispatch } = useUser();

    const [messageOpen, setMessageOpen] = useState(true);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    // need log in if there is a cookie but no user
    const needLogIn = () => {
        const hasCookie = (cookies.jwt || cookies !== 'undefined') ? true : false;
        return hasCookie && !state.user;
    }
    

    return (
        <nav className="border z-30 relative">
            <div className="w-full flex items-center justify-between bg-white mx-auto p-4 relative">
                <Link to="/" className="hidden lg:flex items-center">
                    <img src="/logo/normative-dark.svg" className="h-8" alt="Normative Logo" />
                </Link>

                <button
                    data-collapse-toggle="navbar-default"
                    type="button"
                    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                    aria-controls="navbar-default"
                    aria-expanded={isNavOpen}
                    onClick={toggleNav}
                >
                    <span className="sr-only">Open main menu</span>
                    <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 17 14"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h15M1 7h15M1 13h15"
                        />
                    </svg>
                </button>
                {/* Default expanded Nav buttons */}
                <div className={`${isNavOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0 md:opacity-100 md:translate-y-0'} -z-50 md:z-10 top-full left-0 absolute md:static transition-all w-full md:block md:w-3/5`} id="navbar-default">
                    <div className="font-medium flex flex-col p-4 md:p-0 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent">
                        <NavLink to="/" className={({ isActive }) => isActive ? "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 ππmd:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"} aria-current="page">Home</NavLink>
                        <NavLink to="/features" className={({ isActive }) => isActive ? "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"} aria-current="page">Features</NavLink>
                        <NavLink to="/faq" className={({ isActive }) => isActive ? "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"}>FAQ</NavLink>
                        <NavLink to="/documentation" className={({ isActive }) => isActive ? "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"}>Documentation</NavLink>
                        <NavLink to="/about" className={({ isActive }) => isActive ? "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"}>About</NavLink>
                        <hr className="md:hidden border-gray-200 my-2" />
                        <a href="https://legacy.normative.ca" className="block py-2 px-3 text-normative-blue hover:bg-gray-100 md:hover:bg-transparent md:border-l-2 md:hover:text-blue-700 md:pl-8 md:py-0">Old Version</a>
                    </div>
                </div>


                <div className="flex gap-2">
                    {needLogIn()
                        ? <>
                            <Link to="/researcher-login" className="py-3 px-6 rounded-lg font-medium active:scale-95 hover:text-gray-400">Log in</Link>
                            <GoToButton to="/researcher-signup" text="Sign up" height={2} />
                        </>
                        : <>
                            <AccountBubble />
                            <GoToButton to="/dashboard" text="Dashboard" height={2} />
                        </>}
                </div>
            </div>
            {messageOpen && <div className="absolute flex items-center justify-between px-3 w-full top-full bg-[length:200%_100%] animate-bg-shine bg-[linear-gradient(110deg,#3e54e6dd,45%,#664BBB,55%,#3e54e6dd)] text-white border-zinc-300">
                <h1 className=" text-center py-2 flex-grow">Welcome to the newly designed Normative platform! Experience enhanced reports, improved performance, and a modern interface. Or, use the <a href='https://legacy.normative.ca' className='underline'>old version</a>.</h1>
                <button onClick={() => setMessageOpen(false)} className="text-white p-1 rounded-full text-lg transition-all hover:bg-white/35">
                    <MdClose />
                </button>
            </div>}
        </nav>
    );
};

export default Navbar;